import imgAutomatic from '@assets/images/img-automatic.png'
import imgAdministrative from '@assets/images/img-save-time-administrative.png'
import imgTsqPlanning from '@assets/images/img-tailor-intelligent-planning.png'
import imgPlanning from '@assets/images/img-uptodate-planning.png'

const content = [
    {
        title: 'Planning intelligent',
        description:
            "Reposez-vous sur l'intelligence artificielle et sur le machine learning pour optimiser vos plannings. Staffea vous suggère automatiquement des roulements selon les besoins de votre clinique et les préférences de vos collaborateurs.",
        image: imgTsqPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
    },
    {
        title: 'Gain de temps administratif',
        description:
            "Terminé, les tâches chronophages avec notre logiciel de gestion des temps ! Les plannings, demandes d'échanges d'horaires entre salariés, demandes d'absences, arrêts maladies et congés sont intégrés et mis à jour automatiquement sur Staffea.",
        image: imgAdministrative,
        alt: 'Staffea, application web écran des demandes de congés',
    },
    {
        title: 'Planning à jour',
        description:
            "Apportez autant de modifications que vous le souhaitez aux plannings : ils sont mis à jour en temps réel. Toutes les heures réellement effectuées sont comptabilisées dans votre logiciel d'optimisation de planning, pour faciliter le calcul des éléments variables de paie.",
        image: imgPlanning,
        alt: 'Staffea, application web écran de gestion du planning des managers',
    },
    {
        title: 'Maintenance automatique',
        description:
            "Toutes les mises à jour de Staffea sont incluses dans votre abonnement, alors ne vous tracassez pas avec la maintenance et les sauvegardes. Notre équipe technique s'occupe de tout !",
        image: imgAutomatic,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
]

export default content
