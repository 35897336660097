import automationIcon from '@assets/svg/picto-automation.svg'
import contractIcon from '@assets/svg/picto-contract.svg'
import paymentIcon from '@assets/svg/picto-payment.svg'
import planningIcon from '@assets/svg/picto-planning.svg'
import replacementIcon from '@assets/svg/picto-replacement.svg'
import transfertIcon from '@assets/svg/picto-transfert.svg'

const functionalities = [
    {
        id: 1,
        media: planningIcon,
        title: 'Création des plannings en 2 heures',
        content:
            'Notre logiciel de planning du personnel de clinique réduit considérablement le temps que vous consacrez à la création des trames de planning.',
    },
    {
        id: 2,
        media: automationIcon,
        title: 'Génération automatique des roulements',
        content:
            "Grâce à l'intelligence artificielle et au machine learning, le logiciel Staffea génère automatiquement des roulements et les attribue à une ou plusieurs personnes.",
    },
    {
        id: 3,
        media: paymentIcon,
        title: 'Transmission des éléments de pré-paie à votre logiciel',
        content:
            "Pour vous faire gagner du temps, Staffea s'interface à votre logiciel de paie. Il calcule et transmet vos éléments variables de paie automatiquement, sans ressaisie.",
    },
    {
        id: 4,
        media: replacementIcon,
        title: 'Traitement rapide des demandes de remplacement',
        content:
            'Vos salariés indiquent leurs disponibilités ou indisponibilités sur leurs jours de repos afin de faciliter les remplacements. Ils sont alertés en temps réel en cas de créneau à pourvoir.',
    },
    {
        id: 5,
        media: transfertIcon,
        title: "Bourse d'échanges d'horaires entre salariés",
        content:
            "Permettez à vos collaborateurs d'échanger des horaires ou des jours facilement, via l'application Staffea. Vous recevez les demandes d'échanges et n'avez plus qu'à valider.",
    },
    {
        id: 6,
        media: contractIcon,
        title: "Génération des contrats de travail et d'intérim",
        content:
            "Notre logiciel de création de planning génère aussi vos contrats de travail et d'intérim. Gérez vos embauches à distance avec la signature électronique.",
    },
]

export default functionalities
