import imgMobileAvailabilities from '@assets/images/mobile-availabilities.png'
import imgMobileExchange from '@assets/images/mobile-exchange.png'
import imgMobileNotifications from '@assets/images/mobile-notifications.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'

const content = [
    {
        title: 'Consultation de leur planning en temps réel',
        description: "Vos collaborateurs accèdent à leur planning où et quand ils veulent, via l'application Staffea.",
        image: imgMobilePlanning,
        alt: 'Staffea, application mobile consultation du planning',
    },
    {
        title: 'Envoi de leurs disponibilités',
        description:
            'Ils partagent leurs disponibilités facilement, pour simplifier les remplacements et préserver les équipes.',
        image: imgMobileAvailabilities,
        alt: 'Staffea, application mobile affichage des disponibilités',
    },
    {
        title: "Échange d'horaires entre collaborateurs",
        description: "Les demandes de modification d'horaires sont envoyées au gestionnaire pour validation.",
        image: imgMobileExchange,
        alt: 'Staffea, application mobile échange de jours entre collaborateurs',
    },
    {
        title: 'Notifications push immédiates',
        description: 'Les employés reçoivent chaque changement de planning en temps réel.',
        image: imgMobileNotifications,
        alt: 'Staffea application mobile affichage notification push',
    },
]

export default content
